<template>
    <div>
        <img src="@/assets/img/home/Bautizo/18.jpg" class="w-100 h-100 img-background" alt="Novios abrazados">
        
      
      <HomeCard v-for="(card, index) in cards" :datos="card" :key="index" :id="'home-card-'+index" class="mvh-lg-100" />
    </div>
  </template>
  <script>
    import HomeCard from '@/components/home/Card.vue'
    import Img1 from '@/assets/img/home/Bautizo/19.jpg'
    import Img1_2 from '@/assets/img/home/2-1.png'
    import Img1_3 from '@/assets/img/home/2-2.png'
  
    import Img2 from '@/assets/img/home/Bautizo/20.jpg'
    import Img2_2 from '@/assets/img/home/3-1.png'
  
    import Img3 from '@/assets/img/home/Bautizo/21.jpg'
    import Img3_2 from '@/assets/img/home/4-1.png'
    
    export default {
      name: 'Home',
      components: {
        HomeCard
      },
      data(){
        return {
          cards: [{
            title: 'Celebra con Nosotros tu Bautizo',
            descripcion: " Queridos amigos y familiares, Nos complace enormemente reunirnos hoy para celebrar un momento muy especial en la vida de nuestro querido hijo e hija en su bautizo. Este evento marca el inicio de su viaje espiritual y su introducción a la comunidad cristiana, y estamos emocionados de compartirlo con todos ustedes. El bautismo es un sacramento cargado de significado. Es un acto de fe y compromiso, donde nuestro hijo e hija es recibido con amor en la familia de Dios. A través del agua bendita y las oraciones de los presentes, le damos la bienvenida a la comunidad cristiana y le pedimos a Dios que lo(a) guíe y lo(a) proteja en su vida. En este día, también queremos rendir homenaje a los padres , quienes han tomado la decisión de bautizar a su hijo(a) y de criarle en la fe cristiana. Su dedicación y amor hacia sus hijos son evidentes, y estamos seguros de que serán unos maravillosos guías espirituales en su vida. A medida que celebramos este bautizo, también nos unimos en oración por los hijos. Oramos para que crezca en sabiduría y gracia, y que siempre sepa que está rodeado de amor y apoyo en su camino espiritual. Que este bautizo sea el comienzo de una vida llena de bendiciones y de una profunda relación con Dios. Después de la ceremonia, los invitamos a unirse a nosotros para una recepción en honor a los hijos. Será una ocasión para compartir momentos felices, disfrutar de buena comida y celebrar la nueva incorporación a nuestra familia espiritual. Esperamos que todos ustedes se unan a nosotros en esta celebración de alegría y fe. Una vez más, queremos agradecerles por estar aquí hoy y por ser parte de este momento tan especial en la vida de nuestro hijo e hija. Su presencia y sus bendiciones significan mucho para nosotros, y esperamos que este día esté lleno de amor, alegría y bendiciones para todos.",
            img1: Img1,
            img2: Img1_2,
            img3: Img1_3,
            bg: 'bg-primary text-white',
            align: 'end',
            url: 'como-funciona.index',
            urlname: 'Como funciona',
            urlClass: 'btn-light',
            hide: true
          },
          {
            title: 'Aprovecha cada Momento',
            descripcion: ' En la luz de esta mañana brillante, un alma pura se encuentra ante el altar. Con ternura y amor, en este día santo, celebramos el bautizo de un nuevo corazón. En aguas benditas, el amor se derrama, el cielo sonríe, la tierra aclama. Un niño/nina es bendecido/a hoy, con el toque divino, en un dulce arroyo. Sus risas son notas en el viento que fluye, sus ojos reflejan el cielo que se abre. En brazos de fe y amor incondicional, se cimenta el camino de este ser celestial. Que la luz divina guíe cada paso, que la paz y el amor sean su abrazo. En el sendero de la vida, que encuentre su luz, bajo la gracia de Dios, en cada amanecer, cada atardecer. Así, en este día de gracia y gozo, elevamos nuestras voces en alabanza y aliento. Porque en este bautizo, un alma se eleva,en amor eterno, en la luz que nunca se desvanece. Que la vida de este niño/nina, en su viaje eterno, sea una canción de esperanza, un poema tierno. Y que en cada paso, en cada sendero, sienta siempre el amor que lo/la sostiene, ¡amor sincero! ¡Bendito sea este día, de amor y comunión, en el bautizo de este alma, en su bendita unión! Que así sea, en cada corazón que palpita, bajo el manto sagrado, en esta dulce cita. ',
            img1: Img2,
            img2: Img2_2,
            bg: 'bg-secondary text-white',
            align: 'start',
            hide: true,
          },
          {
            title: 'Comparte',
            descripcion: ' Queridos amigos y familiares: Estamos encantados de invitarles a unirse a nosotros en una ocasión tan especial: el bautizo de nuestro amado bebe. Este evento no solo marca un hito espiritual en la vida de nuestro pequeño, sino que también es una oportunidad para reunirnos y celebrar con nuestros seres queridos. ¿Por Qué Asistir? Un Momento Único: Acompáñanos en una ceremonia llena de significado y amor, donde nuestro hijo e hija recibirá su primer sacramento y será acogido en la comunidad de fe. Celebración Familiar: Después de la ceremonia, compartiremos una recepción especial con deliciosa comida, música y momentos inolvidables. ¡Tu presencia hará de este día aún más memorable! Recuerdos Invaluables: Capturemos juntos sonrisas y emociones en un ambiente lleno de alegría y bendiciones. Estas memorias serán atesoradas por siempre. Confirma tu Asistencia: Para ayudarnos a planificar mejor, por favor confirma tu asistencia antes del Fecha Límite de Confirmación. Tu presencia será un honor y una bendición para nuestra familia. Esperamos contar contigo en este día tan especial para celebrar la vida y el amor de nuestro pequeño.',
            img1: Img3,
            img2: Img3_2,
            bg: 'bg-light text-dark',
            align: 'end',
            hide: true
          }]
        }
      },
      mounted(){
        window.addEventListener('scroll', this.handleScroll);
      },
      unmounted(){
        window.removeEventListener('scroll', this.handleScroll)
      },
      methods: {
        handleScroll () {
          for (let index = 0; index < this.cards.length; index++) {
            var top = document.getElementById('home-card-'+index).offsetTop
            if(document.documentElement.scrollTop >= (top-20)){
              if(this.cards[index]['hide']){
                this.cards[index]['hide'] = false
              }
            }
          }
        }
      }
    }
  </script>
    